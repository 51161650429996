function rotateImagePattern() {
  $(".slider-image-wrapper .slider-image-pattern")
    .addClass("rotate")
    .delay(500)
    .queue(function () {
      $(this).removeClass("rotate").dequeue()
    })
}

$(function () {
  $(".course-slider-btn").on("mouseenter", rotateImagePattern)
})
